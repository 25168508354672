import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { IoIosAddCircle } from "react-icons/io";
import { IoArrowBack, IoCheckmark, IoInformationCircle } from "react-icons/io5";

import { Button, Container, Wrapper, Row, Box } from "../../components/util";
import { CallToAction, CountrySpecificContent } from "../../components/site";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

const PricingCard = styled(Box)`
  max-width: calc(100% / 2);
  padding: 20px 50px;
  /* border-radius: ${(props) => props.theme.styles.borderRadius};
  background-color: #f4f4f4; */
  text-align: left;
  border-right: solid 1px ${(props) => props.theme.colours.greyDarken};

  p {
    font-size: 1.2rem;
    margin-bottom: 0px;
  }
  
  &.-first {
    border-left: solid 1px ${(props) => props.theme.colours.greyDarken};
  }
  .price {
    font-size: 5rem;
  }
  .price-detail {
    margin-left: 12px;
    text-align: left;

    p {
      font-size: inherit;
      font-weight: 500;
      margin-bottom: 0;
    }
  }
  h4 {
    font-weight: inherit;
  }

  span {
    pointer-events: none;
    position: relative;

    @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
      width: 100%;
    }

    &.-hasLink {
      color: #00adef;
    }
    
    &.-hasDescription {
      padding-bottom: 3px;
      pointer-events: all;
      border-bottom: dotted 1px #ccc;

      &:hover {
        border-bottom: dotted 1px #999;
      }

      @media(max-width: ${(props) => props.theme.breakpoints.mobile}){
        padding-bottom: 10px;
      }
    }

    .info {
      color: #999;
      font-size: 1.5rem;
      pointer-events: none;
      position: absolute;
      right: 3px;
      top: calc(50% - 5px);
      transform: translateY(-50%);
    }
  }

  .arrow {
    svg {
      font-size: 1.3rem;
      position: relative;
      top: 3px;
      color: ${(props) => props.theme.colours.dark};

      @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        transform: rotate(90deg);
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    max-width: 100%;
    border-right: none;
    border-bottom: solid 1px ${(props) => props.theme.colours.greyDarken};
    margin-bottom: 30px;
    padding: 10px 10px 40px;

    &.-first {
      border-left: none;
    }

    .price {
      width: min-content;
    }

    .features p {
      padding-bottom: 3px;
    }
  }
`;

// const AddOnCard = styled(Box)`
//   padding: 40px 40px 40px 50px;
//   border-radius: ${(props) => props.theme.styles.borderRadius};
//   background-color: ${(props) => props.theme.colours.white};
//   max-width: 750px;
//   border: solid 1px ${(props) => props.theme.colours.greyDarken};

//   &.-addOn {
//     padding: 50px 40px 40px;

//     .plus {
//       top: 0;
//       left: 50%;
//     }
//   }

//   .plus {
//     position: absolute;
//     top: 50%;
//     left: 0;
//     margin-top: 0;
//     transform: translate(-50%, -50%);
//     font-size: 4.2rem;
//     background-color: ${(props) => props.theme.colours.white};
//     color: ${(props) => props.theme.colours.Primary};
//     border-radius: 100px;
//     padding: 7px;
//   }

//   .name,
//   .cost,
//   .base {
//     font-weight: 600;
//   }

//   .cost {
//     font-size: 3.2rem;
//     font-weight: 600;
//   }

//   .cost_detail {
//     color: ${(props) => props.theme.colours.midDark};
//     line-height: 1.4;
//   }

//   .base {
//     font-size: 1rem;
//   }

//   @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
//     padding: 40px 30px 30px;
//     margin-top: 30px;

//     .plus {
//       left: 50%;
//       top: 0;
//     }

//     .addon-cost {
//       justify-content: center;
//     }

//     .base {
//       text-align: center;
//     }
//   }
// `;

const Feature = styled.h4`
  display: flex;
  align-items: center;

  > svg {
    flex: 0 0 auto;
    color: ${(props) => props.theme.colours.green};
    margin-right: 10px;
  }
`;

const FAQsList = styled(Row)`
  --item-gap: 60px;
  align-items: stretch;

  width: calc(100% + var(--item-gap));

  > div {
    width: calc((100% / 2) - var(--item-gap));
    margin-right: var(--item-gap);
    margin-bottom: var(--item-gap);

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      width: 100%;
      margin-right: 0;
      margin-bottom: calc(var(--item-gap) / 1.7);
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

const Pricing = (props) => {
  const Plans = [
    {
      name: "Business",
      cost: "$6",
      baseFee: "$79",
      signupLink: "/recruitment/signup",
      features: [
        {
          name: "Online Timesheets",
          link: "https://www.karmly.com/recruitment/features/timesheets",
          description: "Contractors can record hours on any device and admins can quickly add time in bulk."
        },
        {
          name: "Client Approvals",
          description: "Clients can view and approve time and expenses without logging in."
        },
        {
          name: "Customisable Invoices",
          link: "https://www.karmly.com/recruitment/features/invoicing",
          description: "Create and send large batches of invoices, tailored to each client's requirements."
        },
        {
          name: "Placement Onboarding",
          link: "https://www.karmly.com/recruitment/features/onboarding",
          description: "Get contractors to complete a set of tasks at the start of a new placement."
        },
        {
          name: "Overtime & Allowances",
          description: "Automatically trigger overtime rates, deductions and allowances rates based on rules you set."
        },
        {
          name: "Reminder Messaging",
          link: "https://www.karmly.com/recruitment/features/messaging",
          description: "Schedule automated messages to chase up overdue timesheets and approvals."
        },
        {
          name: "Agency Branding",
          description: "Add your own branding to the login screen, invoices and emails."
        },
        {
          name: "Custom Reporting",
          link: "https://www.karmly.com/recruitment/features/reporting",
          description: "Use account data to build your own reports and track key metrics."
        },
        {
          name: "Standard Email Support",
          description: "Get in touch with our expert support team via email."
        }
      ]
    },
    {
      name: "Enterprise",
      cost: "$7.50",
      baseFee: "$79",
      signupLink: "/recruitment/signup",
      features: [
        {
          name: (
            <b className="arrow">
              <IoArrowBack /> Everything in Business, plus:
            </b>
          )
        },
        {
          name: "eInvoicing",
          country: "New Zealand, Australia",
          link: "https://www.karmly.com/recruitment/features/einvoicing",
          description: "Send and receive invoices directly via a secure global network."
        },
        {
          name: "Commission Calculations",
          description: "Set up custom commission plans and targets for consultants and teams."
        },
        {
          name: "Bullhorn & Jobadder Integrations",
          link: "https://www.karmly.com/recruitment/integrations",
          description: "Share data with applicant tracking and recruitment management solutions."
        },
        {
          name: "PowerBI Data Models & Reporting",
          description: "Integrate with PowerBI to combine reporting data and unlock more insights."
        },
        {
          name: "Journal Reporting",
          description: "Report on invoice details and link to account codes."
        },
        {
          name: "API Access",
          description: "Request API access for custom integrations (subject to approval)."
        }
      ]
    }
  ];

  // const AddOns = [
  //   {
  //     addon: "PowerBI",
  //     description: [
  //       <p>
  //         Level up your reporting with Microsoft PowerBI. Start with Invoxy's
  //         pre-formatted PowerBI template and build from there. PowerBI gives you
  //         the ability to report on information across multiple tables in a
  //         single report.
  //       </p>,
  //       <p>
  //         Contact <a href="mailto:support@karmly.com">support@karmly.com</a> for
  //         pricing.
  //       </p>,
  //     ],
  //     link: "",
  //     country: null,
  //   },
  //   {
  //     addon: "PayHero",
  //     description: [
  //       <p>
  //         Need payroll? Get single sign on two way integration between Invoxy
  //         and PayHero to share candidate data, timesheets, leave and expenses
  //         for fast and accurate payroll and billing.
  //       </p>,
  //     ],
  //     link: "/payhero",
  //     country: ["New Zealand"],
  //   },
  // ];

  const FAQs = [
    {
      question: "Do I have to sign a long-term contract?",
      answer: (
        <p>
          No, you can cancel a month-to-month subscription with 30 days' notice.
        </p>
      ),
    },
    {
      question: "What types of payment do you accept?",
      answer: (
        <p>
          We accept Visa and Mastercard
          <CountrySpecificContent forCountry={["New Zealand", "Australia"]}>
            <CountrySpecificContent forCountry={["New Zealand"]}>
              {" "} or direct debit payments
            </CountrySpecificContent>. For payment on invoice please{" "}
            <Link to="/recruitment/contact">contact us</Link>
          </CountrySpecificContent>.
        </p>
      ),
    },
    {
      question: "What will I be billed?",
      answer: (
        <p>
          We bill at the start of each month for usage in the previous month.
        </p>
      ),
    },
    {
      question: "What is a Resource?",
      answer: (
        <p>
          A resource is a candidate who records time against a placement
          within the month or is placed on a permanent placement within the
          month.
        </p>
      ),
    },
    {
      question: "Are there any extra setup fees?",
      answer: (
        <p>
          There are no compulsory setup fees. We don’t offer data migration and
          account set up services, but our friendly{" "}
          <Link to="/recruitment/contact">support team</Link> is always happy to help out as
          you get things set up. Alternatively,{" "}
          <Link to="/recruitment/partner-directory">our partners</Link> can offer more hands-on
          implementation services if required.
        </p>
      ),
    },
    {
      question: "Is my payment safe and secure?",
      answer: (
        <p>
          We take data security very seriously and have partnered with Payment
          Express to securely handle credit card information and payments.
          Payment Express is certified to PCI Service Provider Level 1, the
          highest level of payment security accreditation available.
        </p>
      ),
    },
    {
      question: "What currency will my subscription be charged in?",
      answer: (
        <p>
          The currency of your subscription charge is based on the country
          selected when you set up your Karmly account. Karmly accounts with the
          country set as New Zealand are charged in NZD and Australia in AUD.
          Karmly accounts with countries outside New Zealand and Australia are
          charged in US dollars.
        </p>
      ),
    },
    {
      question: "How does the free trial work?",
      answer: (
        <p>
          Once you sign up you get free access to Karmly for 14 days. During the
          trial you can access all features. You don’t need to enter a credit
          card to start your trial. You can choose to subscribe and enter
          payment details at any point during or after your trial and all data
          entered during the trial period will be retained.
        </p>
      ),
    },
    {
      question: "How is my monthly price calculated?",
      answer: (
        <>
          <p>
            Payment is in arrears and charged on the first of each month based
            on the number of resources who record a time entry in the previous
            month, or were on a permanent placement dated in the month.
          </p>
          <p>
            Your first payment will be on the first day of the month following
            the date you subscribe, and will be pro-rated.
          </p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <Seo
        title="Pricing | Karmly"
        description="There’s no need to pick a plan or compare features – just pay for active placements each month. Try 14 Days Free."
        pathname={props.location.pathname}
      />
      <Container>
        <Wrapper>
          <Box stackGap={40}>
            <Box stackGap={7} className="-textCenter">
              <h1>Choose the plan that fits your needs</h1>
              <h4>14 Days Free | No credit card required</h4>
            </Box>
            <Row justify="center" css={{ alignItems: "stretch" }}>
              {Plans.map((plan, i) => {
                return (
                  <PricingCard size={40} stackGap={40} className={i == 0 ? "-first" : ""}>
                    <Box stackGap={3}>
                      <h2 css={{ color: "#5BA079" }}>{plan.name}</h2>
                      <Box isFlex="true">
                        <span className="price -fontBold">{plan.cost}</span>
                        <Box stackGap={3} className="price-detail">
                          <p>per resource</p>
                          <p>per month</p>
                        </Box>
                      </Box>
                      <p css={{ fontWeight: 600 }}>
                        {" "}
                        + {plan.baseFee} base fee per month{" "}
                      </p>
                    </Box>
                    <Box stackGap={10}>
                      <Button
                        className="primary -md -full gtm-cta"
                        to={plan.signupLink}
                      >
                        Start Free Trial
                      </Button>
                    </Box>
                    <Box stackGap={20} className="features -fontNormal">
                      {plan.features.map((feature, i) => {
                        if (feature.country != null) {
                          return (
                            <CountrySpecificContent forCountry={feature.country}>
                              <Row>
                                <span
                                  className={`${feature.description ? "-hasDescription" : ""
                                    } ${feature.link ? "-hasLink" : ""}`}
                                  data-tooltip={feature.description}
                                >
                                  {feature.link ? (
                                    <p><Link to={feature.link}>{feature.name}</Link></p>
                                  ) : (
                                    <p key={i}>{feature.name}</p>
                                  )}
                                  {feature.description && (
                                    <IoInformationCircle className="info showOnMobile" />
                                  )}
                                </span>
                              </Row>
                            </CountrySpecificContent>
                          );
                        } else {
                          return (
                            <Row>
                              <span
                                className={`${feature.description ? "-hasDescription" : ""
                                  } ${feature.link ? "-hasLink" : ""}`}
                                data-tooltip={feature.description}
                              >
                                {feature.link ? (
                                  <p><Link to={feature.link}>{feature.name}</Link></p>
                                ) : (
                                  <p key={i}>{feature.name}</p>
                                )}
                                {feature.description && (
                                  <IoInformationCircle className="info showOnMobile" />
                                )}
                              </span>
                            </Row>
                          );
                        }
                      })}
                    </Box>
                  </PricingCard>
                );
              })}
            </Row>
            <Box className="-textCenter" stackGap={10}>
              <p css={{ opacity: 0.5 }}>
                Prices are in{" "}
                <CountrySpecificContent forCountry={["New Zealand"]}>
                  NZD and exclude GST
                </CountrySpecificContent>
                <CountrySpecificContent forCountry={["Australia"]}>
                  AUD and exclude GST
                </CountrySpecificContent>
                <CountrySpecificContent forCountry={[null]} restOfWorld>
                  USD
                </CountrySpecificContent>
                .
              </p>
            </Box>
            {/* <Box stackGap={20}>
              {AddOns.map((item, i) => {
                return (
                  <CountrySpecificContent forCountry={item.country}>
                    <AddOnCard key={i} className="-center">
                      <Row alignCenter stackGap={30}>
                        <Box css={{ flex: "1" }} stackGap={20}>
                          <Box size={100} stackGap={15} centerOnMobile>
                            <h4 className="name">{item.addon}</h4>
                            {item.description}
                          </Box>
                        </Box>
                        {item.link && (
                          <Box>
                            <Link className="link-floating" to={item.link}>
                              Learn More
                            </Link>
                          </Box>
                        )}
                      </Row>
                      <IoIosAddCircle className="plus" />
                    </AddOnCard>
                  </CountrySpecificContent>
                );
              })}
            </Box> */}
          </Box>
        </Wrapper>
      </Container>
      <Container bg="lightGrey">
        <Wrapper>
          <h2 className="-textCenter">Frequently asked questions</h2>
          <Box stackGap={60}>
            <FAQsList isWrap justify="flex-start">
              {FAQs.map((item, i) => {
                return (
                  <Box key={i} stackGap={10} size={50}>
                    <h4>
                      <b>{item.question}</b>
                    </h4>
                    {item.answer}
                  </Box>
                );
              })}
            </FAQsList>
          </Box>
          <p className="-textCenter">
            Have any other questions? Feel free to{" "}
            <Link to="/recruitment/contact">get in touch</Link>.
          </p>
        </Wrapper>
      </Container>
      <CallToAction />
    </Layout>
  );
};

export default Pricing;
